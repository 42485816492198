import * as React from "react"

import { Page, PageHeader, PageSection, SEO } from "src/components"

export default function TermsOfServicePage() {
    return (
        <Page>
            <SEO title="Terms of Service"/>

            <PageHeader header="Terms of Service" />

            <PageSection>
                <a id="introduction"><h1>Introduction</h1></a>
                <p>Hello</p>
            </PageSection>
        </Page>
    )
}
